export const menuItems = [
  {
    id: 1,
    label: "menuitems.menu.text",

    isTitle: true,
  },
  {
    id: 2,
    label: "menuitems.dashboard.text",
    name: "Dashboard",
    icon: "mdi mdi-view-dashboard",
    link: "/dashboard",
    hide: false,
  },
  {
    id: 6,
    label: "menuitems.docmigration.text",
    name: "DocMigration",
    icon: "mdi mdi-file-replace",
    link: "/docmigration",
    disabled: false,
  },
  {
    id: 3,
    label: "menuitems.docmanager.text",
    name: "DocManager",
    icon: "mdi mdi-file-document-outline",
    link: "/docmanager",
    hide: false,
  },
  {
    id: 4,
    label: "menuitems.doceditor.text",
    name: "DocEditor",
    icon: "ri-file-edit-fill",
    link: "/doceditor",
    hide: false,
  },
  {
    id: 5,
    label: "menuitems.docpublisher.text",
    name: "DocPublisher",
    icon: "mdi mdi-file-export",
    link: "/docpublisher",
    hide: false,
  },
];
